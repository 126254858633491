/* nprogress.css */
#nprogress {
    pointer-events: none;
  }
  
  #nprogress .bar {
    background: #8781c8;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px; /* Grubość paska */
  }
  
  #nprogress .peg {
    display: block;
    position: absolute;
    z-index: 1031;
    right: 0;
    width: 100px; /* Długość paska przy końcu */
    height: 100%; /* Wysokość */
    background: #8781c8; /* Zmień na wybrany kolor */
    box-shadow: 0 0 10px #4caf50, 0 0 5px #4caf50; /* Efekt cienia */
  }
  
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px; /* Wysokość spinnera */
    right: 15px; /* Odległość od prawej */
  }
  
  #nprogress .spinner .outer {
    border: 3px solid transparent;
    border-top-color: #8781c8; /* Zmień kolor spinnera */
    border-radius: 50%;
    width: 20px; /* Średnica spinnera */
    height: 20px; /* Średnica spinnera */
    animation: nprogress-spinner 0.6s linear infinite; /* Animacja spinnera */
  }
  
  #nprogress .spinner .inner {
    border: 3px solid transparent;
    border-top-color: #8781c8; /* Zmień kolor spinnera */
    border-radius: 50%;
    width: 10px; /* Średnica wewnętrznego spinnera */
    height: 10px; /* Średnica wewnętrznego spinnera */
    position: absolute;
    top: 5px; /* Wysokość wewnętrznego spinnera */
    left: 5px; /* Odległość od lewej */
  }
  
  @keyframes nprogress-spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  