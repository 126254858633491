@tailwind base;
@tailwind components;
@tailwind utilities;




  .header{
    background-color: #0f0f15;
    border-bottom: 1px solid hsla(0,0%,100%,.1)

  }

  .menu {
    color:#fff;
    font-weight: 500;
    font-size: .975rem;
    position: relative
}


a.menu {
  background-image: linear-gradient(
    to right,
    #8781c8,
    #8781c8 50%,
    #fff 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

a.menu:before{
  content: '';
  background: #8781c8;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 1px;
  transition: all 0.3s ease-in-out;
}

a.menu:hover {
 background-position: 0;
}

a.menu:hover::before{
  width: 50%;
}



.hero{
  height: 550px;
  background-color: #0f0f15;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonnav
{
  background-image: linear-gradient(15deg, #8781c8, #2f49db);
  background-size:125%;
  transition: background-size .25s;
}

.oferta{
  background-color: #F8F8FC;
}



.wb span{
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(15deg, #8781c8, #2f49db);
}

.footer
{
  background-color: #141424;
}

/* Styles.css */
.img-gray {
  filter: grayscale(100%);
  transition: filter 0.3s ease; /* Animacja przejścia */
}

.img-gray:hover {
  filter: grayscale(20%); /* Zmiana koloru na niebieski (brak szarości) */
}

.moon{
  opacity: .025;
  width: 1250px;
  height: 1250px;
  position: absolute;
  bottom: -950px;
  margin-inline:auto;pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.realizacje{
  background-color: #0F0F15;
}

.techno{
  padding: .25rem .75rem;
  color: #2f49db;
  border: 1px solid rgba(135,129,200,.125);
  border-radius: .75rem;
  font-size: .925rem;
  font-weight: 500;
  background-color: rgba(47,73,219,.05)
}

.techno1{
  gap: .75rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: .75rem;
}